import React, { useEffect, useRef, useState } from 'react'
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { motion } from "framer-motion"
import Experties from '../Experties/Experties';


// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import './Home.css';

import { TbSquareRoundedNumber1 } from "react-icons/tb";
import { TbSquareRoundedNumber2 } from "react-icons/tb";
import { TbSquareRoundedNumber3 } from "react-icons/tb";
import omm from '../../assets/omm.png'
import kalash from '../../assets/kalash.png'
import kalashomm from '../../assets/Logo-10-ai.png'
import { SiFreelancer } from "react-icons/si";
import { FaStar } from "react-icons/fa";
import InfoCard from '../InfoCard/InfoCard';
import PanditInfoCard from '../PanditInfoCard/PanditInfoCard';
import panditphoto from '../../assets/panditphoto.jpeg'


function Home() {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  // // const [pageChange, setPageChange] = useState(false)
  const onAutoplayTimeLeft = (s, time, progress) => {
    //   progressCircle.current.style.setProperty('--progress', 1 - progress);
    //   progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  console.log('hello')

  const swiperRef = useRef(null);
  const slideTitleRefs = useRef([]);

  useEffect(() => {
    const swiper = swiperRef.current.swiper;
    swiper.on('slideChange', () => {
      const activeIndex = swiper.activeIndex;
      gsap.fromTo(
        slideTitleRefs.current[activeIndex],
        { x: -500, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 1.6,
          delay: activeIndex * 0.2,
          ease: 'power3.out',
        }
      );
    });
  }, []);

  const handleSlideClick = (index) => {
    gsap.to(slideTitleRefs.current[index], {
      y: 0,
      opacity: 1,
      duration: 0.8,
      ease: 'power3.out',
    });
  };

  const panditData = {
    panditName: "पंडित शंकर व्यास",
    panditExperience: "10",
    contactInfo: "77424 77520",
    panditImage: panditphoto, // Add the URL of the Pandit image here
    services: [
      "अंगारक दोष पूजा",
      "काल सर्प दोष पूजा",
      "नव ग्रह शांति पूजा",
      "मंगल दोष पूजा",
      "रुद्र अभिषेक पूजा"
    ]
  };



  const heroimg = [
    {
      head: 'मंगल दोष की शांति पूजा',
      subhead: 'मंगल दोष के प्रभाव को कम करें और जीवन में शांति और समृद्धि लाएं।',
      button: 'और जानें',
      img: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/12-ark-vivah-600x600.jpg',
      bgColor: '#FFE4D1', // Soft peach for positivity and warmth
    },
    {
      head: 'काल सर्प दोष निवारण',
      subhead: 'काल सर्प दोष के प्रभाव को दूर करें और बाधाओं से मुक्ति पाएं।',
      button: 'और जानें',
      img: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/kaal-sarp-dosh-300x300.png',
      bgColor: '#EAF7E1', // Light green for healing and growth
    },
    {
      head: 'महामृत्युंजय मंत्र जाप',
      subhead: 'भगवान शिव की कृपा से दीर्घायु और सुरक्षा पाएं।',
      button: 'और जानें',
      img: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2023/05/angarak-dosh-300x300.png',
      bgColor: '#E3EDFD', // Soft blue for peace and divine connection
    },
  ];


  const heroServices = [
    {
      image: omm,
      no: <TbSquareRoundedNumber1 />,
      head: "ओएमएम योग और वेलनेस",
      desc: "हमारे योग सत्रों से शांति और विश्राम प्राप्त करें, जो आपके शरीर, मन और आत्मा को फिर से जीवित करने के लिए डिज़ाइन किए गए हैं।"
    },
    {
      image: kalash,
      no: <TbSquareRoundedNumber2 />,
      head: "परंपरागत कलश समारोह",
      desc: "कलश समारोह में भाग लें, यह एक पवित्र परंपरा है जो नए आरंभ और समृद्धि लाती है।"
    },
    {
      image: kalashomm,
      no: <TbSquareRoundedNumber3 />,
      head: "कलश और ओएमएम रिट्रीट",
      desc: "हमारे रिट्रीट में कलश समारोह और ओएमएम तकनीकें मिलकर आध्यात्मिक और शारीरिक उपचार प्रदान करती हैं।"
    }
  ];


  const expertiseCard = [
    {
      imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/12-ark-vivah-600x600.jpg',
      tech: "अंगारक दोष पूजा",
      desc: "अंगारक दोष (Angarak Dosh) कुंडली में ग्रहों के सही या विपरीत स्थिति के कारण उत्पन्न होता है। इसे सही पूजन द्वारा शुभकारी बनाया जा सकता है, लेकिन अगर समय रहते इसका सही से प्रबंधन न किया जाए तो इसके परिणाम हानिकारक हो सकते हैं। अंगारक दोष निवारण के लिए उज्जैन में पंडितजी से परामर्श करके पूजा करवाई जा सकती है।"
    },
    {
      imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/12-ark-vivah-600x600.jpg',
      tech: "आर्क विवाह पूजा उज्जैन",
      desc: "आर्क विवाह पूजा (Ark Vivah Puja) एक विशेष प्रकार की विवाह पूजा है, जो विशेष रूप से वैदिक शास्त्रों के अनुसार विधिपूर्वक संपन्न की जाती है। इस पूजा से वैवाहिक जीवन में सुख-शांति और समृद्धि आती है।"
    },
    {
      imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/12-ark-vivah-600x600.jpg',
      tech: "काल सर्प दोष पूजा उज्जैन",
      desc: "काल सर्प दोष (Kaal Sarp Dosh) एक प्रमुख ग्रह दोष होता है, जो जब सभी ग्रह राहु और केतु के बीच होते हैं, तो कुंडली में इसके प्रभाव से जीवन में कई समस्याएं उत्पन्न होती हैं। उज्जैन में विशेष पूजा विधियों के द्वारा इस दोष का निवारण किया जा सकता है।"
    },
    {
      imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/maha-mratyunjay-jaap-300x300.png',
      tech: "महामृत्युंजय जप पूजा उज्जैन",
      desc: "महामृत्युंजय मंत्र का जाप (Maha Mrityunjay Jaap) मृत्यु के भय को दूर करने और जीवन की लंबाई बढ़ाने के लिए विशेष रूप से किया जाता है। यह पूजा व्यक्ति को स्वास्थ्य, समृद्धि और मानसिक शांति प्रदान करती है।"
    },
    {
      imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/5864-e1615797550896-300x300.png',
      tech: "मंगल दोष पूजा उज्जैन",
      desc: "मंगल दोष निवारण पूजा (Mangal Dosh Nivaran Puja) के लिए उज्जैन का मंगलनाथ मंदिर एक प्रमुख स्थल है। यह पूजा जीवन के विभिन्न क्षेत्रों जैसे स्वास्थ्य, भौतिक सुख, और वैवाहिक जीवन को सुधारने में मदद करती है।"
    },
    {
      imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/navgrah-300x300.png',
      tech: "नव ग्रह शांति पूजा उज्जैन",
      desc: "नव ग्रह शांति पूजा (Nav Grah Shanti Puja) सभी ग्रहों के संतुलन को बनाए रखने और उनके नकारात्मक प्रभाव को दूर करने के लिए की जाती है। यह पूजा विशेष रूप से जीवन में शांति और समृद्धि लाने के लिए होती है।"
    },
    {
      imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/pitra-dosh-nivaran-puja-300x300.jpg',
      tech: "पितृ दोष निवारण पूजा उज्जैन",
      desc: "पितृ दोष (Pitra Dosh) व्यक्ति के पूर्वजों से जुड़ी हुई समस्याओं का परिणाम है। इस दोष का निवारण करने के लिए उज्जैन में पितृ दोष निवारण पूजा की जाती है, जिससे परिवार में सुख-शांति और समृद्धि आती है।"
    },
    {
      imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/rurudra-abhishek-300x300.png',
      tech: "रुद्र अभिषेक पूजा उज्जैन",
      desc: "रुद्र अभिषेक पूजा (Rudra Abhishek Puja) भगवान शिव की पूजा का एक अत्यंत प्रभावशाली तरीका है, जो विशेष रूप से जीवन के संकटों को दूर करने और समृद्धि पाने के लिए की जाती है।"
    },

  ];

  const projectStatus = [
    {
      statusCount: '60+',
      statusName: 'client served'
    },
    {
      statusCount: '150+',
      statusName: 'Project Completed'
    },
    {
      statusCount: '97%',
      statusName: 'Project Successes'
    },
    {
      statusCount: '90M',
      statusName: 'Revenue From Client'
    },

  ]

  const testimonial = [
    {
      dp: 'https://images.unsplash.com/photo-1711828255780-c26a7789288d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      name: 'राज चौधरी',
      desc: "उज्जैन पंडित जी द्वारा कराई गई पूजा अत्यंत प्रभावशाली और शांति देने वाली थी। उनका तरीका और समर्पण वाकई अद्वितीय है। पूजा के दौरान आशीर्वाद प्राप्त होने का अनुभव हुआ और घर में सुख-शांति का माहौल बना।",
    },
    {
      dp: 'https://images.unsplash.com/photo-1711828255780-c26a7789288d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      name: 'राज चौधरी',
      desc: "पंडित जी के मार्गदर्शन में की गई पूजा ने हमारे घर में सकारात्मक ऊर्जा का संचार किया। उनका ध्यान और पूजा विधि सटीक और प्रभावी थी। हमें जो शांति और आशीर्वाद मिला, वह अविस्मरणीय है।",
    },
    {
      dp: 'https://images.unsplash.com/photo-1711828255780-c26a7789288d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      name: 'राज चौधरी',
      desc: "हमने पंडित जी से हमारी परिवारिक पूजा कराई थी और परिणाम से हम बेहद संतुष्ट हैं। उनकी पूजा विधि और उपदेशों से हमारे घर में सुख-शांति का वातावरण बना और सभी समस्याएं धीरे-धीरे समाप्त हो गईं।",
    },
    {
      dp: 'https://images.unsplash.com/photo-1711828255780-c26a7789288d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      name: 'राज चौधरी',
      desc: "उज्जैन के पंडित जी के साथ हमारा अनुभव बहुत ही आध्यात्मिक था। उन्होंने हमें पूजा के महत्व को समझाया और हर चरण में हमारा मार्गदर्शन किया। परिणामस्वरूप, घर में समृद्धि और सुख का माहौल है।",
    },
  ]


  const awards = [
    { img: '../public/Images/Logo-10-ai.png' },
    { img: '../public/Images/Logo-10-ai.png' },
    { img: '../public/Images/Logo-10-ai.png' },
  ]

  const reviewBox = [
    { icon: <SiFreelancer />, head: 'Reviews', rating: '4.6/5', star: <FaStar /> },
    { icon: <SiFreelancer />, head: 'Reviews', rating: '4.6/5', star: <FaStar /> },
    { icon: <SiFreelancer />, head: 'Reviews', rating: '4.6/5', star: <FaStar /> },
    { icon: <SiFreelancer />, head: 'Reviews', rating: '4.6/5', star: <FaStar /> },
    { icon: <SiFreelancer />, head: 'Reviews', rating: '4.6/5', star: <FaStar /> },
    { icon: <SiFreelancer />, head: 'Reviews', rating: '4.6/5', star: <FaStar /> },
  ]

  const pujaDataArray = [
    {
      title: "मंगल दोष पूजा उज्जैन",
      description: [
        "मंगल दोष निवारण पूजा (Mangal Dosh Nivaran Puja) के लिए उज्जैन का मंगलनाथ मंदिर एक प्रमुख स्थल है। यह पूजा जीवन के विभिन्न क्षेत्रों जैसे स्वास्थ्य, भौतिक सुख, और वैवाहिक जीवन को सुधारने में मदद करती है।"
      ],
      sections: [
        {
          heading: "लक्षण",
          items: [
            "स्वास्थ्य और वैवाहिक जीवन में समस्याएं।",
            "व्यवसाय में असफलताएं।"
          ]
        },
        {
          heading: "मंगल दोष निवारण",
          items: [
            "मंगल दोष पूजा के माध्यम से जीवन में सुख-शांति और समृद्धि लाना।"
          ]
        },
        {
          heading: "उज्जैन में मंगल दोष पूजा",
          content: "उज्जैन में मंगल दोष शांति पूजा के लिए प्रमुख स्थल मंगलनाथ मंदिर है।"
        },
        {
          heading: "मंगल दोष पूजा का खर्च",
          description: "मंगल दोष पूजा का खर्च ₹2100 से ₹5100 तक हो सकता है।",
          items: [
            "सामान्य पूजा: ₹2100",
            "विशेष पूजा: ₹3100",
            "महा पूजा: ₹4100"
          ],
          note: "पूजा बुकिंग और जानकारी के लिए पंडित जी से संपर्क करें।"
        }
      ]
    },
    {
      title: "काल सर्प दोष पूजा उज्जैन",
      description: [
        "काल सर्प दोष (Kaal Sarp Dosh) एक प्रमुख ग्रह दोष होता है, जो जब सभी ग्रह राहु और केतु के बीच होते हैं, तो कुंडली में इसके प्रभाव से जीवन में कई समस्याएं उत्पन्न होती हैं।"
      ],
      sections: [
        {
          heading: "लक्षण",
          items: [
            "नौकरी, व्यापार या पारिवारिक जीवन में अड़चनें आना।",
            "स्वास्थ्य समस्याएं और मानसिक तनाव।"
          ]
        },
        {
          heading: "काल सर्प दोष निवारण",
          items: [
            "विशेष पूजा विधियाँ और हवन के माध्यम से दोष का निवारण।"
          ]
        },
        {
          heading: "उज्जैन में काल सर्प दोष पूजा",
          content: "उज्जैन के पवित्र स्थानों जैसे मंगलनाथ और अंगारेश्वर महादेव मंदिर में काल सर्प दोष पूजा की जाती है।"
        },
        {
          heading: "काल सर्प दोष पूजा का खर्च",
          description: "काल सर्प दोष पूजा का खर्च ₹3000 से ₹6000 तक हो सकता है।",
          items: [
            "सामान्य पूजा: ₹3100",
            "विशेष पूजा: ₹5100"
          ],
          note: "पूजा बुकिंग और जानकारी के लिए पंडित जी से संपर्क करें।"
        }
      ]
    },
    {
      title: "महामृत्युंजय जप पूजा उज्जैन",
      description: [
        "महामृत्युंजय मंत्र का जाप (Maha Mrityunjay Jaap) मृत्यु के भय को दूर करने और जीवन की लंबाई बढ़ाने के लिए किया जाता है।"
      ],

    },

    {
      title: "नव ग्रह शांति पूजा उज्जैन",
      description: [
        "नव ग्रह शांति पूजा (Nav Grah Shanti Puja) सभी ग्रहों के संतुलन को बनाए रखने और उनके नकारात्मक प्रभाव को दूर करने के लिए की जाती है।"
      ],

    },
    {
      title: "पितृ दोष निवारण पूजा उज्जैन",
      description: [
        "पितृ दोष (Pitra Dosh) व्यक्ति के पूर्वजों से जुड़ी हुई समस्याओं का परिणाम है। इस दोष का निवारण करने के लिए उज्जैन में पितृ दोष निवारण पूजा की जाती है, जिससे परिवार में सुख-शांति और समृद्धि आती है।"
      ],

    },
    {
      title: "रुद्र अभिषेक पूजा उज्जैन",
      description: [
        "रुद्र अभिषेक पूजा (Rudra Abhishek Puja) भगवान शिव के प्रमुख पूजा विधियों में से एक है। यह पूजा भगवान शिव को प्रसन्न करने और जीवन में सुख-शांति की प्राप्ति के लिए की जाती है।"
      ],

    },
    {
      title: "अंगारक दोष पूजा",
      description: [
        "अंगारक दोष (Angarak Dosh) कुंडली में ग्रहों के सही या विपरीत स्थिति के कारण उत्पन्न होता है। इसे सही पूजन द्वारा शुभकारी बनाया जा सकता है, लेकिन अगर समय रहते इसका सही से प्रबंधन न किया जाए तो इसके परिणाम हानिकारक हो सकते हैं।"
      ]
    },
    {
      title: "आर्क विवाह पूजा उज्जैन",
      description: [
        "आर्क विवाह पूजा (Ark Vivah Puja) एक विशेष प्रकार की विवाह पूजा है, जो विशेष रूप से वैदिक शास्त्रों के अनुसार विधिपूर्वक संपन्न की जाती है।"
      ],

    },
  ];



  return (
    <>
      <main>
        <div className="hero">
          <div className='hero-section w-full'>
            <Swiper
              ref={swiperRef} spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper">

              {heroimg.map((elem, index) => (
                <SwiperSlide key={index} onClick={() => handleSlideClick(0)}>
                  <img src={elem.img} alt="" />
                  <div ref={(ref) => (slideTitleRefs.current[index] = ref)} className="heroImgDesc w-[36vw] h-fit z-[99] bg-[#63d391] absolute py-10 px-6 text-start rounded-md" style={{ backgroundColor: elem.bgColor }} >
                    <h2 className='text-5xl font-semibold'>{elem.head}</h2>
                    <p className='text-xl py-4'>{elem.subhead}</p>
                    {/* <button className='bg-zinc-100 py-2 px-4 text-md rounded-md'>{elem.button}</button> */}
                  </div>
                </SwiperSlide>
              ))}


            </Swiper>
          </div>

          <div className="hero-services w-full md:h-fit py-4 px-4 flex justify-evenly items-center flex-wrap gap-10">
            {heroServices.map((item, index) => (
              <div key={item.index} className="hero-service-box flex flex-col items-center gap-4 w-full md:w-[24vw] p-6 rounded-lg shadow-2xl bg-white">
                {/* Local Image */}
                <div className="icon">
                  <img
                    src={item.image}
                    alt="Service Icon"
                    className="h-16 w-16 object-contain"
                  />
                </div>
                {/* Description */}
                <div className="desc text-center">
                  <h2 className="text-xl font-semibold text-gray-800">{item.head}</h2>
                  <p className="text-md text-gray-600 mt-2">{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="expertise">
          <h4 className='text-xl font-semibold text-center uppercase'>सभी समाधान यहीं हैं, पंडितजी उज्जैन</h4>
          <h2>काल सर्प दोष पूजा उज्जैन<span className='text-[#63d392]'></span></h2>

          <div className="expertiseSection relative">
            {expertiseCard.map((exp, index) => {
              console.log(exp)
              return (
                <Experties key={index} data={exp} />
              )
            }
            )}
          </div>

        </div>


        {pujaDataArray.map((puja, index) => (
          <InfoCard key={index} data={puja} />
        ))}

        <PanditInfoCard data={panditData} />






      </main>
    </>
  )
}

export default Home