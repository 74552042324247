import React from 'react';
import './Experties.css';
import { Link } from 'react-router-dom';


function Experties({ data }) {
    console.log(data, "exp");
    return (
        <>
                <Link to="/ServicesDetail" state={{ service: data }} className="no-underline">

            <div className="flip-card">
                <div className="flip-card-inner">
                    <div
                        className="flip-card-front px-2 py-2 rounded-md"
                        style={{
                            backgroundImage: `url(${data.imageUrl})`, // Set background image here
                            backgroundSize: 'cover', // Ensure the image covers the card
                            backgroundPosition: 'center', // Center the image in the card
                            backgroundRepeat: 'no-repeat', // Prevent the image from repeating
                        }}
                    >
                        <div className="w-fit m-auto">
                            <span className="text-6xl">{data.icon}</span>
                        </div>
                        <h2 className="text-lg py-1">{data.tech}</h2>
                    </div>
                    <div className="flip-card-back rounded-md overflow-auto p-2 px-1">
                        <p>{data.desc}</p>
                    </div>
                </div>
            </div>
            </Link>
        </>
    );
}

export default Experties;
