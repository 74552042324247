import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom'
import App from './App.jsx'
import './index.css'
import Home from './Components/Home/Home.jsx'
import About from './Components/About/About.jsx'
import Services from './Components/Services/Services.jsx'
import ServiceDetail from './Components/ServiceDetail/ServiceDetail.jsx'
import Contact from './Components/Contact/Contact.jsx'



const router = createHashRouter([
  {
    path:'/',
    element:<App/>,
    children:[
      { 
        path:"",
        element: <Home/>
      },
      { 
        path:"About",
        element: <About/>
      },
      { 
        path:"Services",
        element: <Services/>
      },
      { 
        path:"ServicesDetail",
        element: <ServiceDetail/>
      },
      { 
        path:"Contact",
        element: <Contact/>
      },
    ]
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router}/>
)