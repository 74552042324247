import React from 'react';
import { useLocation } from 'react-router-dom';
import './ServiceDetail.css';

function ServiceDetail() {
    const location = useLocation();
    const { service } = location.state || {}; // Retrieve the passed data

    if (!service) {
        return <div>No service data available!</div>;
    }

    return (
        <div className="service-detail">
            <div className="service-card">
                <div className="service-image-wrapper">
                    <img src={service.imageUrl} alt={service.tech} className="service-image" />
                </div>
                <div className="service-info">
                    <h1 className="service-title">{service.tech}</h1>
                    <p className="service-description">{service.desc}</p>
                </div>
            </div>
        </div>
    );
}

export default ServiceDetail;
