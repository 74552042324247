import React from "react";
import profile from '../../assets/profile.jpeg'


const links = [
  { name: "हमारी सेवाएं", href: "services" },
  { name: "संपर्क करें", href: "contact" },
];

const stats = [
  { name: "वर्षों का अनुभव", value: "10+" },
  { name: "संतुष्ट ग्राहक", value: "1000+" },
  { name: "पूजा प्रकार", value: "20+" },
  { name: "सेवा के स्थान", value: "उज्जैन" },
];

function About() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff8c00] to-[#ff5f00] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-center">
          {/* Text Content */}
          <div>
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              हमारे बारे में
            </h2>
            <h4 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              पं. शंकर व्यास
            </h4>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              उज्जैन सभी प्रकार के अनुष्ठान एवं कर्मकाण्ड के लिए भी प्रसिद्ध हैं। उज्जैन
              में सिद्ध कुर्मचक्र स्थित हैं जो की सभी प्रकार की सिद्धियों और साधनाओ के
              लिए आवश्यक हैं। उज्जैन वेदों के ज्ञाता, धर्मं शास्त्रियो, ज्योतिर्विद और
              विद्वान पंडित/पुरोहितो के लिए जाना जाता हैं।
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              हमारी सेवा का उद्देश्य न केवल आपके घर को आध्यात्मिक रूप से शुद्ध करना है,
              बल्कि आपको मानसिक शांति और समृद्धि की प्राप्ति दिलाना है। हम विभिन्न प्रकार
              की पूजा और हवन सेवाएं प्रदान करते हैं, जो आपके घर और जीवन की शांति और सुख
              के लिए उपयुक्त हैं।
            </p>
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10 mt-10">
              {links.map((link) => (
                <a key={link.name} href={link.href}>
                  {link.name} <span aria-hidden="true">&rarr;</span>
                </a>
              ))}
            </div>
          </div>

          {/* Pandit Photo */}
          <div className="flex justify-center">
            <img
              src={profile}
              alt="Pandit Shankar Vyas"
              className="rounded-lg shadow-lg object-cover w-96 h-96"
            />
          </div>
        </div>

        {/* Stats */}
        <div className="mx-auto mt-16 max-w-2xl lg:mx-0 lg:max-w-none">
          <dl className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.name} className="flex flex-col-reverse">
                <dt className="text-base leading-7 text-gray-300">
                  {stat.name}
                </dt>
                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default About;
