import React from "react";

const PanditInfoCard = ({ data }) => {
  return (
    <div className="w-full max-w-4xl mx-auto py-10 px-6 bg-gradient-to-r from-[#FF7A00] to-[#D62828] shadow-lg rounded-lg border border-gray-300 mt-6 mb-6">

      {/* Pandit Photo */}
      <div className="mt-6 flex justify-center">
        <img
          src={data.panditImage}
          alt="Pandit"
          className="w-40 h-40 rounded-full border-4 border-white"
        />
      </div>

      {/* Pandit Details */}
      <div className="mt-6">
        <h3 className="text-3xl font-semibold text-white">पंडित जानकारी</h3>
        <p className="text-lg mt-4 text-white leading-8">
          <strong>पंडित का नाम:</strong> {data.panditName}
        </p>
        <p className="text-lg mt-4 text-white leading-8">
          <strong>पंडित का अनुभव:</strong> {data.panditExperience} साल
        </p>
        <p className="text-lg mt-4 text-white leading-8">
          <strong>संपर्क:</strong> {data.contactInfo}
        </p>
      </div>

      {/* Services */}
      <div className="mt-6">
        <h3 className="text-3xl font-semibold text-white">सेवा विवरण</h3>
        <ul className="text-lg mt-4 text-white leading-8 list-disc pl-8">
          {data.services.map((service, index) => (
            <li key={index}>{service}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PanditInfoCard;
