import React from "react";

const InfoCard = ({ data }) => {
    return (
        <div className="w-full max-w-4xl mx-auto py-10 px-6 bg-white shadow-lg rounded-lg border border-gray-300 mt-6 mb-6">
            {/* Title */}
            <h2 className="text-4xl font-semibold text-center text-[#E63946]">
                {data.title}
            </h2>

            {/* Description */}
            {data.description.map((desc, index) => (
                <p key={index} className="text-lg mt-4 text-gray-700 leading-8">
                    {desc}
                </p>
            ))}

            {/* Sections */}

            {
                data?.sections &&

                (data?.sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className="mt-10">
                        <h3 className="text-3xl font-semibold text-[#D62828]">
                            {section.heading}
                        </h3>
                        {/* Items */}
                        {section.items && (
                            <ul className="text-lg mt-4 text-gray-700 leading-8 list-disc pl-8">
                                {section.items.map((item, itemIndex) => (
                                    <li key={itemIndex}>{item}</li>
                                ))}
                            </ul>
                        )}
                        {/* Content */}
                        {section.content && (
                            <p className="text-lg mt-4 text-gray-700 leading-8">
                                {section.content}
                            </p>
                        )}
                        {/* Note */}
                        {section.note && (
                            <p className="text-lg mt-4 text-gray-700 leading-8 font-medium">
                                {section.note}
                            </p>
                        )}
                    </div>
                )))
            }
        </div>
    );
};

export default InfoCard;
