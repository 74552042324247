import React, { useEffect, useRef, useState } from 'react'
import Experties from '../Experties/Experties';
import { FaReact } from "react-icons/fa";
import { TbBrandReactNative } from "react-icons/tb";
import { FaNode } from "react-icons/fa6";
import { MdWeb } from "react-icons/md";
import { SiAndroidstudio } from "react-icons/si";
import { SiHiveBlockchain } from "react-icons/si";
import { DiRuby } from "react-icons/di";
import { FaPython } from "react-icons/fa";
import { FaWordpress } from "react-icons/fa";
import { SiAdobephotoshop } from "react-icons/si";

function Services() {

  const expertiseCard = [
    { 
        imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/12-ark-vivah-600x600.jpg', 
        tech: "अंगारक दोष पूजन", 
        desc: "अंगारक दोष (Angarak Dosh) का कुंडली में होना जन्म के समय ग्रहों का समान या विषम परिस्थितियों में होना माना जाता है। यह गुण भी है और दोष भी है, अर्थात अगर इसका सही से पूजन इत्यादि के द्वारा प्रभाव को डाइवर्ट कर दिया जाये तो यह मंगलकारी होता है, लेकिन अगर समय रहते इसका सही से प्रबंध न किया जाये तो परिणाम सुखद नहीं होते हैं। अंगारक दोष निवारण (Angarak Dosh Nivaran) के लिए उज्जैन ही एकमात्र स्थान है, यहाँ पर आप भगवान की शरण में अपने अंगारक दोष का निवारण (Angarak Dosh Nivaran Puja Ujjain) कर सकते हैं।"
    },
    { 
        imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/12-ark-vivah-600x600.jpg', 
        tech: "अर्क विवाह पूजन", 
        desc: "अर्क विवाह (Ark Vivah) एक धार्मिक प्रक्रिया है जो विशेष रूप से उच्‍च जाति के लोगों द्वारा कराई जाती है। इस पूजन के द्वारा विवाह के विघ्न और दुर्योगों का निवारण किया जाता है।"
    },
    { 
        imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/12-ark-vivah-600x600.jpg', 
        tech: "काल सर्प दोष पूजन", 
        desc: "काल सर्प दोष (Kaal Sarp Dosh) एक गंभीर दोष है, जो कुंडली में सूर्य और चंद्रमा के बीच स्थिति के कारण उत्पन्न होता है। इस दोष से परेशान व्यक्तियों को जीवन में अनेक समस्याओं का सामना करना पड़ता है। काल सर्प दोष निवारण पूजन से इस दोष के प्रभाव को कम किया जा सकता है।"
    },
    { 
        imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/maha-mratyunjay-jaap-300x300.png', 
        tech: "महामृत्युंजय जाप", 
        desc: "महामृत्युंजय जाप (Maha Mrityunjay Jaap) एक अत्यंत प्रभावशाली और शक्तिशाली मंत्र है, जो जीवन की लंबाई और शांति के लिए किया जाता है। यह जाप मृत्यु के डर और अन्य विपत्तियों से बचाने के लिए किया जाता है।"
    },
    { 
        imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/5864-e1615797550896-300x300.png', 
        tech: "मंगल दोष पूजन", 
        desc: "मंगल दोष निवारण पूजन (Mangal Dosh Nivaran Puja Ujjain) के लिए समस्त संसार में सिर्फ उज्जैन ही एकमात्र स्थल है, यहाँ पर मंगल नाथ का सुप्रसिद्ध मंदिर है जहाँ पर पूजन करने से मनोवांछित फल प्राप्त होता है। मंगल दोष कुंडली में मंगल ग्रह के 1, 4, 7, 8 और 12 वें भाव में होता है।"
    },
    { 
        imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/navgrah-300x300.png', 
        tech: "नव ग्रह शांति पूजन", 
        desc: "नव ग्रह शांति पूजन (Nav Grah Shanti Puja) ग्रहों की स्थिति और उनके प्रभाव को संतुलित करने के लिए की जाती है। इस पूजन से सभी ग्रहों के दोषों का निवारण होता है और जीवन में शांति व समृद्धि आती है।"
    },
    { 
        imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/pitra-dosh-nivaran-puja-300x300.jpg', 
        tech: "पितृ दोष निवारण पूजन", 
        desc: "पितृ दोष निवारण पूजन (Pitra Dosh Nivaran Puja) उन लोगों के लिए है जिनकी कुंडली में पितृ दोष होता है। यह दोष परिवार के सुख और समृद्धि को प्रभावित करता है। पितृ दोष निवारण के लिए विशेष रूप से इस पूजन का आयोजन किया जाता है।"
    },
    { 
        imageUrl: 'https://cdn-ilcbbjd.nitrocdn.com/PcmAIoFgIeDreGgciMNEeqAQknvOnavW/assets/images/optimized/rev-21c743a/panditjiujjain.com/wp-content/uploads/2021/03/rurudra-abhishek-300x300.png', 
        tech: "रूद्र अभिषेक पूजन", 
        desc: "रूद्र अभिषेक पूजन (Rudra Abhishek Puja) शिवजी के रूद्र रूप की पूजा है। यह पूजन व्यक्ति के जीवन में आने वाली सभी समस्याओं को समाप्त करने और भगवान शिव की कृपा प्राप्त करने के लिए की जाती है।"
    }
];

  return (
    <>
      <div className="expertise">
        
          <div className="expertiseSection relative">
            {expertiseCard.map((exp, index) => {
              console.log(exp)
              return (
                <Experties key={index} data={exp} />
              )
            }
            )}
          </div>

        </div>
    </>
  )
}

export default Services