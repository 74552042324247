import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { RiTwitterXFill } from 'react-icons/ri';
import { Link } from 'react-router-dom'; // Import Link from React Router
import logo from "../../assets/Logo-10-ai.png"

function Footer() {
  return (
    <>
      <footer className='w-full h-fit bg-zinc-300 py-4'>
        {/* Logo Section */}
        <div className="w-full h-fit m-auto flex items-center justify-center overflow-hidden">
          <img src={logo} alt="Logo" className='w-[50px]' />
        </div>

        {/* Navigation Links */}
        <div className="navlinks w-fit m-auto">
          <ul className='flex flex-wrap gap-7 w-full py-4 text-center'>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>

        {/* Footer Bottom */}
        <div className="w-full text-center py-4 flex justify-center items-center gap-10">
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-2xl">
            <FaInstagram />
          </a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-2xl">
            <FaFacebook />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-2xl">
            <RiTwitterXFill />
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;
